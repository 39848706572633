import _ from 'lodash'
import * as Yup from 'yup'

Yup.addMethod(Yup.number, 'number', function (message) {
  return this.test('number', message || 'Value must be number', (value) =>
    value ? /^[0-9]*$/.test(value) : true
  )
})

Yup.addMethod(Yup.number, 'decimal', function (message) {
  return this.test('decimal', message || 'Value must be a valid decimal', (value) =>
    value ? /^\d{1,13}(\.\d{1,6})?$/.test(value) : true
  )
})

Yup.addMethod(Yup.number, 'exchangeRate', function (message) {
  return this.test('exchangeRate', message || 'Value must be maximum 6 decimal', (value) =>
    value ? /^\d{1,13}(\.\d{1,6})?$/.test(value) : true
  )
})

Yup.addMethod(Yup.string, 'url', function (message) {
  return this.test('url', message || 'Enter a valid website', (value) =>
    value
      ? /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g.test(
          value
        )
      : true
  )
})

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test(
    'unique',
    message,
    (list) => list.filter(mapper).length === new Set(list.filter(mapper).map(mapper)).size
  )
})

Yup.addMethod(
  Yup.array,
  'atLeastOneOfFieldInObject',
  function (message = 'At least one of field is required', field) {
    return this.test('atLeastOneOfFieldInObject', '', (value, ref) =>
      !value.some((obj) => obj[field])
        ? ref.createError({
            message,
            path: `${ref.path}[0].${field}`
          })
        : true
    )
  }
)

Yup.addMethod(Yup.string, 'dateFormat', function (message) {
  return this.test('dateFormat', message || 'Enter a valid date format', (value) =>
    value ? /^.*\$\{.*((Y)|(M)|(D)).*\}.*/g.test(value) : true
  )
})

Yup.addMethod(Yup.array, 'batchTotal', function (deliveryField, message) {
  return this.test('batchTotal', message || 'Qty and Total Batch / Serial Qty not equal', function (value) {
    return _.sumBy(value, (o) => Number(o.quantity)) === Number(this.parent[deliveryField])
  })
})

Yup.addMethod(Yup.string, 'barcode', function (message) {
  return this.test('barcode', message || '${path} is not valid', (value) =>
    value ? /^\*?([0-9A-Z\-. $/+%]{1,})\*?$/.test(value) : true
  )
})
